/*!

 =========================================================
 * Light Bootstrap Dashboard Angular - v1.7.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-angular2
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import "lbd/variables";
@import "lbd/mixins";

@import "lbd/typography";

// Core CSS
@import "lbd/misc";
@import "lbd/sidebar-and-main-panel";
@import "lbd/buttons";
@import "lbd/inputs";

@import "lbd/alerts";
@import "lbd/tables";

@import "lbd/checkbox-radio-switch";
@import "lbd/navbars";
@import "lbd/footers";

// Fancy Stuff
@import "lbd/dropdown";
@import "lbd/cards";
@import "lbd/chartist";
@import "lbd/responsive";

.top-space{
    height: 20px !important;
}
.hidden{
    display: none;
}
.side-spaces{
    padding-left: 20px;
    padding-right: 20px;
}
.bottom-space{
    padding-bottom: 20px;
}